
import formularmodal from '~/mixins/formulare/formularmodal';

export default {
  mixins: [formularmodal],
  data() {
    return {
      modal: {
        id: 'anruf-starten-fall-erstellen',
        event: 'create::anruf-starten-fall-erstellen',
        titel: '',
        modelname: 'Anruf',
        model: 'anrufe'
      },
    }
  },
  methods: {
    speichern() {
      this.$$store('anrufe', this.model).then(response => {
        this.$bvModal.hide(this.modalid);
        this.$success('Anruf gestartet');
      });
    },

  },
  mounted() {}
}
