
export default {
  mixins: [],
  props: [],
  data() {
    return {
      kontakt: null
    }
  },
  computed: {
    kannBearbeiten() {
      if (!this.kontakt.model_type) return false;
      if (this.kontakt.model_type.endsWith('Mitarbeiter')) return this.$can('Mitarbeiter verwalten');
      if (this.kontakt.model_type.endsWith('Patient')) return this.$can('Patienten verwalten');
      if (this.kontakt.model_type.endsWith('Arzt')) return this.$can('Ärzte verwalten');
      if (this.kontakt.model_type.endsWith('Krankenkasse')) return this.$can('Krankenkassen verwalten');
      if (this.kontakt.model_type.endsWith('Einrichtung')) return this.$can('Einrichtungen verwalten');
    }
  },
  watch: {},
  methods: {
    start(kontakt) {
      this.kontakt = kontakt;
      this.abrufen();
      this.$bvModal.show('kontakt-modal');
    },
    set(kontakt) {
      this.kontakt = kontakt;
    },
    abrufen() {
      this.$$fill('kontakte', {with: ['model']});
      this.$$find('kontakte', this.kontakt.id).then(element => {
        this.kontakt = element;
      });
    },
    bearbeiten() {
      if (this.kontakt.model_type.endsWith('Mitarbeiter')) this.$nuxt.$emit('edit-mitarbeiter', this.kontakt.model_id)
      else if (this.kontakt.model_type.endsWith('Patient')) this.$nuxt.$emit('edit-patient', this.kontakt.model_id)
      else if (this.kontakt.model_type.endsWith('Arzt')) this.$nuxt.$emit('edit-arzt', this.kontakt.model_id)
      else if (this.kontakt.model_type.endsWith('Krankenkasse')) this.$nuxt.$emit('edit-krankenkasse', this.kontakt.model_id)
      else if (this.kontakt.model_type.endsWith('Einrichtung')) this.$nuxt.$emit('edit::einrichtung', {model: this.kontakt.model, akademischer_grad: 'Einrichtung bearbeiten'})
    },
    schliessen() {
      this.$bvModal.hide('kontakt-modal');
    }
  },
  mounted() {
    this.$nuxt.$on('display-kontakt', this.start);
    this.$nuxt.$on('update-kontakt', this.start);
    this.$nuxt.$on('refresh::kontakt', this.start);
    this.$nuxt.$on('hide::kontakt', this.schliessen);
  },
  beforeDestroy() {
    this.$nuxt.$off('display-kontakt', this.start);
    this.$nuxt.$off('update-kontakt', this.start);
    this.$nuxt.$off('refresh::kontakt', this.start);
    this.$nuxt.$off('hide::kontakt', this.schliessen);
  }

}
