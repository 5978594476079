
import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'anruf-faelle-uebersicht',
        event: 'display::anruffaelle',
        titel: 'Anruf Aufgaben',
        modelname: 'Anruf',
        model: 'anrufe'
      },
    }
  },
  computed: {},
  watch: {},
  methods: {},
}
