
export default {
  mixins: [],
  props: {
    params: {},
    fallstatus: {default: false},
    mitarbeiter_id: {default: null},
    geschlosseneAufgaben: {default: false},
  },
  data() {
    return {
      page: 1,
    }
  },
  computed: {},
  watch: {
    params(neu) {
      this.$$fill('faelle', neu);
      this.abrufen();
    }
  },
  methods: {
    abrufen(page = 1) {
      this.page = Number(page);
      this.$$abrufen('faelle', page).then(response => {
        this.$emit('total', this.$$response('faelle').total);
      });
    },
    abbrechen() {
      this.$$abbrechen('faelle');
    }
  },
  mounted() {
    this.$$fill('faelle', this.params);
    this.abrufen();

    this.$nuxt.$on('store-fall', this.abrufen);
    this.$nuxt.$on('update-fall', this.abrufen);
    this.$nuxt.$on('hide::patient-faelle-modal', this.abbrechen);
    this.$nuxt.$on('updated::fall', this.abrufen);
    this.$nuxt.$on('refresh::anrufe', this.abrufen);
  },
  beforeDestroy() {
    this.$nuxt.$off('store-fall', this.abrufen);
    this.$nuxt.$off('update-fall', this.abrufen);
    this.$nuxt.$off('hide::patient-faelle-modal', this.abbrechen);
    this.$nuxt.$off('updated::fall', this.abrufen);
    this.$nuxt.$off('refresh::anrufe', this.abrufen);
  },
}
