
export default {
  mixins: [],
  props: {'model': {}, 'bearbeiten': {default:false}, 'betreffender': {default: null}},
  data() {
      return {
        kontaktBearbeiten: false,
        telefonnummer: {
          bezeichnung: '',
          nummer: '',
          kontakt_id: null
        },
        kontaktZwischenspeicher: null,
      }
  },
  computed: {
    betreffenderAnrufStarten() {
      if (this.betreffender) return this.betreffender;
      return this.model;
    }
  },
  watch: {},
  methods: {
    kontaktGewaehlt(kontakt) {
      if (!kontakt || !this.model) return;
      this.kontaktBearbeiten = false;

      // Prüfen ob die Nummer dem Kontakt bereits zugeordnet ist
      let nummerVorhanden = kontakt.telefonnummern?.find(tel =>
        tel.nummer.replace(/\s/g, '') === this.model.kontaktnummer?.replace(/\s/g, '')
      );
      if (nummerVorhanden) this.telefonnummer = nummerVorhanden;
      else {
        this.telefonnummer.kontakt_id = kontakt.id;
        this.$bvModal.show('rufnummer-zuordnen-modal');
      }
    },
    jaZuordnen() {
      this.$bvModal.hide('rufnummer-zuordnen-modal');
      this.$bvModal.show('rufnummer-bezeichung-abfragen-modal');
    },
    resetNummernzuordnung() {
      this.$bvModal.hide('rufnummer-bezeichung-abfragen-modal');
      this.model.kontakt = this.kontaktZwischenspeicher;
      if (this.model?.kontakt) this.telefonnummer = this.model.kontakt.telefonnummern.find(tel => tel.nummer.replace(/\s/g, '') === this.model.kontaktnummer?.replace(/\s/g, ''));
      else this.telefonnummer = {bezeichnung: 'Unbekannt', nummer: this.model.kontaktnummer, kontakt_id: null};
    },
    speichereNummernzuordnung() {
      this.$$store('telefonnummern', this.telefonnummer).then(() => {
        this.$success('Nummer zugeordnet');
        this.$bvModal.hide('rufnummer-bezeichung-abfragen-modal');

        // Kontakt neu laden um die aktualisierte Telefonnummer zu erhalten
        this.$$find('kontakte', this.telefonnummer.kontakt_id).then(kontakt => {
          this.kontaktZwischenspeicher = kontakt;
          this.resetNummernzuordnung();
        });
      });
    },
  },
  mounted() {
    this.kontaktZwischenspeicher = this.model.kontakt;
    this.resetNummernzuordnung();
  },

}
