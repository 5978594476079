import { render, staticRenderFns } from "./index.vue?vue&type=template&id=41c8d291"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayDebugId: require('/builds/logo-yes/portal2022/frontend/components/global/display/DebugId.vue').default,InternKalenderTerminTooltipTitel: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/TerminTooltip/titel.vue').default,InternTermineButtons: require('/builds/logo-yes/portal2022/frontend/components/intern/termine/Buttons.vue').default})
