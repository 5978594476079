
import faelle from '../../../mixins/faelle';
export default {
  mixins: [faelle],
  props: ['mitarbeiter_id', 'geschlosseneAufgaben'],
  data() {
    return {
      total: null,
    }
  },
  computed: {},
  watch: {
    // status(neu) {
    //   this.letzterClickSetzen();
    // }
  },
  methods: {
    // letzterClickSetzen() {
    //   if (!this.patient_id) this.$store.commit('telefonie/setLetzterClick', this.status);
    // },
  },
  mounted() {
    // this.letzterClickSetzen();
  }

}
