import { render, staticRenderFns } from "./Therapie.vue?vue&type=template&id=59b8e056"
import script from "./Therapie.vue?vue&type=script&lang=js"
export * from "./Therapie.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsDetailsTerminFormularTherapiePatienten: require('/builds/logo-yes/portal2022/frontend/components/modals/details/TerminFormular/TherapiePatienten.vue').default})
