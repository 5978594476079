
export default {
  mixins: [],
  props: {
      'anruf': {},
      'zuweisen': {},
      'mitarbeiter_id': {},
      'displayAnrufer': {default: true}
    },
  data() {
    return {
      sprachnachricht: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    sprachnachrichtAbrufen(anruf) {
      this.$axios.get('/api/anrufe/'+anruf.id+'/sprachnachricht').then(response => {
        this.sprachnachricht = response.data;
      })
    },
    nummernobj(anruf) {
      if (!anruf || !anruf.kontakt || !anruf.kontakt.telefonnummern) return null;
      let nummer = anruf.kontakt.telefonnummern.filter(el => {
        return el.nummer.replace(' ', '') == anruf.kontaktnummer;
      })[0];
      return nummer;
    },
    fallErstellen(anruf) {
      this.$$store('faelle');
    },
    hideTooltips() {
      this.$nuxt.$emit('bv::hide::tooltip');
    },
    dokumentieren(anruf) {
      // Mitarbeiter will unter "Meine Anrufe" dokumentieren oder unter "Alle Anrufe" einen Fall erstellen
      if (this.mitarbeiter_id || this.anruf.faelle.length == 0) {
        this.$nuxt.$emit('create::fall-zu-anruf', {model: this.anruf, titel: 'Anruf dokumentieren'});
      }
      // Mitarbeiter will Doku sehen bzw. dokumentieren für alten Fall
      else {
        if (this.anruf.faelle.length == 1) this.$nuxt.$emit('display-fall', this.anruf.faelle[0]);
        else if (this.anruf.faelle.length > 1) this.$nuxt.$emit('display::anruffaelle', {model: this.anruf});
      }
    }
  },
  mounted() {
    this.$nuxt.$on('bv::modal::shown', this.hideTooltips);
  },
  beforeDestroy() {
    this.$nuxt.$off('bv::modal::shown', this.hideTooltips);
  }

}
