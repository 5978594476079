
import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  data() {
    return {
      modal: {
        id: 'anruf-dokumentieren',
        event: 'create::fall-zu-anruf',
        titel: 'Anruf dokumentieren',
        modelname: 'Anruf',
        model: 'anrufe'
      },

      betreffender: null,
      keinKontakt: false,
    }
  },
  computed: {
    faelleKey() {
      if (this.betreffender) return this.betreffender.model_id;
      return 1;
    }
  },
  methods: {
    nachStart() {
      this.betreffender = null;
      this.keinKontakt = false;
    },
    done() {
      this.schliessen();
    },
    beforeHide() {
      this.betreffender = null;
    }
  },
  mounted() {
    this.$nuxt.$on('refresh::anrufe', this.schliessen);
  },
  beforeDestroy() {
    this.$nuxt.$off('refresh::anrufe', this.schliessen);
  }
}
