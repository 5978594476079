export default {
  data() {
    return {
      params: null,
      model: null,
      modalid: 'modal-'+this._uid,
      formkey: 0,
    }

  },
  methods: {
    start(params) {
      this.params = params;
      this.fillModel(params.model);
      if (params.titel) this.modal.titel = params.titel;
      this.oeffnen();
      this.nachStart()
    },
    nachStart() {},
    hide() {
      this.beforeHide();
      this.$nuxt.$emit('refresh::'+this.modal.model);
      this.$nuxt.$emit('hide::'+this.modal.id);
      this.model = null;
      this.formkey++;
    },
    speichern() {
      if (this.model.id) {
        this.$$update(this.modal.model, this.model, this.modal.modelname+' erfolgreich aktualisiert').then(response => {
          this.schliessen()
          // this.$nuxt.emit('updated::'+this.modal.model)
        })
      }
      else {
        this.$$store(this.modal.model, this.model, this.modal.modelname+' erfolgreich angelegt').then(response => {
          this.schliessen()
          // this.$nuxt.emit('stored::'+this.modal.model)
        })
      }
    },
    fillModel(model) {
      this.model = model;
    },
    schliessen() {
      this.beforeClose();
      this.$bvModal.hide(this.modalid);
    },
    oeffnen() {
      this.$bvModal.show(this.modalid);
    },
    beforeClose() {},
    beforeHide() {}
  },
  mounted() {
    _.forEach(this.$makeArray(this.modal.event), event => {
      this.$nuxt.$on(event, this.start);
    });
  },
  beforeDestory() {
    _.forEach(this.$makeArray(this.modal.event), event => {
      this.$nuxt.$off(event, this.start);
    });
  },
}
