
export default {
  props: ['model', 'betreffender'],
  data() {
    return {
      faelle: null,
    }
  },
  watch: {},
  computed: {

  },
  methods: {
    faelleAbrufen() {
      if (!this.betreffender) return;

      let filter = [
        {where: ['betreffender_id', '=', this.betreffender.model_id]},
        {where: ['betreffender_type', '=', this.betreffender.model_type]},
        {nichtGeschlossen: []}
      ];

      this.$$fill('faelle', {filter: filter, with: ['kommentare']});
      this.$$abrufen('faelle').then(response => {
        this.faelle = response;
        if (!this.faelle.length) {
          this.params.aktion = 'erstellen';
          this.keineFaelle = true;
        }
      });
    }
  },
  mounted() {
    this.faelleAbrufen();
    this.model.neuerFall = {titel: '', neuer_kommentar: '', bearbeiter: []}
  },
}
