export default {
  mixins: [],
  props: ['patient_id'],
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  methods: {
    datenAbrufParams(mitarbeiter_id = null, perPage = 10) {
      let orderBy = {'order': 'desc'};
      let filter = [];
      let withModels = [];

      // 0 Meine Aufgaben
      if (mitarbeiter_id) {
        filter = [{whereHasId: ['bearbeiter', mitarbeiter_id]}];
        withModels = ['letzteAenderungModel', 'betreffender', 'termin'];
      }
      // 1 Offene Aufgaben
      else {
        filter = [{whereDoesntHave: ['bearbeiter']}];
        withModels = ['letzteAenderungModel', 'betreffender', 'termin'];
        perPage = 20
      }
      if (this.geschlosseneAufgaben) {
        filter.push({geschlossen: []});
        filter.push({where: ['anruffall', 0]});
      }
      else filter.push({offen: []});
      if (this.patient_id) filter.push({where: ['betreffender_id', this.patient_id]});
      return {filter: filter, with: withModels, perPage: perPage, orderBy: orderBy};
    }
  },
}
