
export default {
  mixins: [],
  props: ['kontakt', 'anzahlElemente'],
  data() {
    return {
      displayDetails: false
    }
  },
  computed: {
    offeneAufgaben() {
      if (this.$$response('faelle')) return this.$$response('faelle').total;
      return null;
    }
  },
  watch: {
    anzahlElemente(neu) {
      if (neu == 1) {
        this.displayDetails = true;
        this.faelleAbrufen();
      }
      else this.displayDetails = false;
    }
  },
  methods: {
    impersonate(mitarbeiter_id) {
      this.$nuxt.$loading.start('Mitarbeiter impersonate');
      this.$axios.put('/api/mitarbeiter/'+mitarbeiter_id+'/impersonate').then(response => {
        this.$auth.fetchUser().then(() => {
          this.$router.push('/');
          this.$nuxt.$loading.finish();
        });
      })
    },
    archivieren(mitarbeiter_id) {
      this.$confirm('Soll der Mitarbeiter wirklich archiviert werden?').then(response => {
        if (response) {
          this.$$delete('mitarbeiter', mitarbeiter_id).then(response => {
            this.$success('Mitarbeiter erfolgreich archiviert');
            this.$emit('refresh');
          })
        }
      })
    },
    tagesabschluss(mitarbeiter_id) {
      this.$nuxt.$emit('display-mitarbeiter-tagesabschluss', {mitarbeiter_id: mitarbeiter_id});
    },
    sendLoginLink(kontakt) {
      this.$$store('sendLoginMail', {email: kontakt.email}, 'Login E-Mail versendet', '/api/mitarbeiter/'+kontakt.model_id+'/sendLoginMail');
    },
    sendVerificationMail(kontakt) {
      this.$$store('sendLoginMail', {email: kontakt.email}, 'E-Mail Verification versendet', '/api/mitarbeiter/'+kontakt.model_id+'/sendVerificationMail');
    },
    mitarbeiterKalender(kontakt) {
      this.$router.push('/intern/kalender');
      setTimeout(() => {
        this.$nuxt.$emit('display-kontakt-kalender', kontakt);
      }, 200)
    },
    faelleAbrufen() {
      if (this.kontakt.model_type != 'App\\Models\\Patient') return;
      let filter = [];
      filter.push({where: ['betreffender_id', this.kontakt.model_id, 'betreffender_type', this.kontakt.model_type]});
      filter.push({offenOderBeiMir: []});
      this.$$fill('faelle', {filter: filter});
      this.$$abrufen('faelle');
    },
    detailsEinblenden() {
      this.displayDetails = !this.displayDetails;
      if (this.displayDetails) {
        this.faelleAbrufen();
      }
    }
  },
  mounted() {
    if (this.anzahlElemente == 1) {
      this.displayDetails = true;
      this.faelleAbrufen();
    }
  },

}
