
import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'patient-faelle-modal',
        event: 'display-patient-faelle-modal',
        titel: 'Aufgaben',
        modelname: 'Aufgaben',
        model: 'faelle'
      },
      kontakt: null,
      displaygeschlossen: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(params) {
      this.kontakt = params.kontakt;
      this.$bvModal.show(this.modalid);
    }
  },

}
