
export default {
  mixins: [],
  props: ['fall', 'fallstatus', 'mitarbeiter_id'],
  data() {
    return {}
  },
  computed: {
    klassen() {
      let klassenname = '';
      if (this.selectbutton) klassenname += ' list-group-item-action ';
      if (this.fallstatus) {
        if (this.fall.status == 0) klassenname += ' bg-danger';
        if (this.fall.status == 2) klassenname += ' bg-success';
        if (this.fall.status == 1) klassenname += ' bg-warning';
        if (this.fall.status == 3) klassenname += ' bg-cyan';
      }
      else if (this.fall.termin && this.fall.status != 2 && this.$moment(this.fall.termin.von).diff(this.$moment(), 'hours') <= 48) klassenname += 'list-group-item-warning';
      return klassenname;
    }
  },
  watch: {},
  methods: {
    fallZuordnen() {
      this.fall.bearbeiter = [this.$model];
      this.fall.status = 1;
      this.$$update('faelle', this.fall).then(response => {
        this.$nuxt.$emit('updated::fall', response);
      });
    }
  },
  mounted() {},

}
