import { render, staticRenderFns } from "./Kontakt.vue?vue&type=template&id=5ce9a55e"
import script from "./Kontakt.vue?vue&type=script&lang=js"
export * from "./Kontakt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsDetailsKontaktdaten: require('/builds/logo-yes/portal2022/frontend/components/modals/details/kontaktdaten.vue').default})
