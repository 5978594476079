import { render, staticRenderFns } from "./FallModal.vue?vue&type=template&id=b8e1e502&scoped=true"
import script from "./FallModal.vue?vue&type=script&lang=js"
export * from "./FallModal.vue?vue&type=script&lang=js"
import style0 from "./FallModal.vue?vue&type=style&index=0&id=b8e1e502&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8e1e502",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternTelefonieFallAnrufe: require('/builds/logo-yes/portal2022/frontend/components/intern/telefonie/FallAnrufe.vue').default,GlobalDisplayFachbereichBadge: require('/builds/logo-yes/portal2022/frontend/components/global/display/FachbereichBadge.vue').default,InternKalenderTerminContent: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/TerminContent.vue').default,InternKalenderTerminVertretungen: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/TerminVertretungen.vue').default})
