
import formularmodal from '~/mixins/formulare/formularmodal';
import termin from '~/mixins/kalender/termin';
export default {
  mixins: [formularmodal, termin],
  props: [],
  data() {
    return {
      modal: {
        id: 'modalid',
        event: 'create::fall-zu-termin',
        titel: null,
        modelname: 'Aufgabe',
        model: 'faelle'
      },
      fall: null,
      termin: null,
    }
  },
  computed: {
    disabled() {
      if (this.fall.titel) return false;
      return true;
    }
  },
  watch: {},
  methods: {
    anlegen() {
      this.$$store('faelle', this.fall).then(response => {
        this.$nuxt.$emit('update-fall', response);
        this.schliessen();
      })
    },
    fillModel(model) {
      let betreffender = model.patienten[0];
      this.fall = {bemerkung: null, neuer_kommentar: null, betreffender_id: betreffender.id, betreffender_type: 'App\\Models\\Patient', termin_id: model.id};
      this.termin = model;
    },
  },

}
