
import Subnav from '~/layouts/template/subnav';
export default {
  components: {Subnav},
  data() {
    return {}
  },
  computed: {
    menuepunkte() {
      if (this.$route.path.includes('/verwaltung/verguetungen')) {
        return  [
          {'bezeichnung': 'Vergütungen', key: 'verguetungen', route: '/verwaltung/verguetungen', if: true},
          {'bezeichnung': 'Krankenkassenarten', key: 'krankenkassenarten', route: '/verwaltung/verguetungen/krankenkassenarten', if: true},
          {'bezeichnung': 'Kostenpunkte', key: 'kostenpunkte', route: '/verwaltung/verguetungen/kostenpunkte', if: true},
        ];
      }
      else if (this.$route.path.includes('/verwaltung/cloud')) {
        return  [
          {'bezeichnung': 'Cloudtags', key: 'cloud', route: '/verwaltung/cloud', if: this.$can('Cloudtags verwalten')},
        ];
      }
      else if (this.$route.path.includes('/intern/listen')) {
        return  [
          {'bezeichnung': 'Ärzte', key: 'aerzte', route: '/intern/listen/aerzte', if: true},
          {'bezeichnung': 'Einrichtungen', key: 'einrichtungen', route: '/intern/listen/einrichtungen', if: true},
          {'bezeichnung': 'ICD Codes', key: 'icdcodes', route: '/intern/listen/icdcodes', if: true},
          {'bezeichnung': 'Diagnosegruppen', key: 'diagnosegruppen', route: '/intern/listen/diagnosegruppen', if: true},
          {'bezeichnung': 'Freie Slots', key: 'freieslots', route: '/intern/listen/freieslots', if: true},
        ];
      }
    }
  },
}
