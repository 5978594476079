
export default {
  mixins: [],
  props: ['model', 'ausgehend'],
  data() {
      return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},

}
