
import AnrufFaelleUebersicht from './AnrufFaelleUebersicht';
export default {
  components: {
    AnrufFaelleUebersicht
  },
  mixins: [],
  props: ['mitarbeiter_id', 'patient_id'],
  data() {
    return {
      details: true,
      anrufe: [],
      response: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    abrufen(page = 1) {
      this.$$abbrechen('anrufe');
      this.$$abrufen('anrufe', page).then((anrufe) => {
        this.anrufe = anrufe;
        this.response = this.$$response('anrufe');
      });
    },
    abrufenAktuelleSeite() {
      if (this.$$response('anrufe')) this.abrufen(this.$$response('anrufe').current_page);
      else this.abrufen();
    }
  },
  mounted() {
    let filter = [];
    let withObj = ['mitarbeiterTelefon', 'patienten', 'kontakt', 'sipAccount.mitarbeiter'];
    if (this.mitarbeiter_id) {
      filter.push({filterMitarbeiterId: [this.mitarbeiter_id]});
      filter.push({whereDoesntHave: ['faelle']});
    }
    // Fälle nur anzeigen, wenn alle Anrufe angezeigt werden und nicht nur die vom Mitarbeiter
    else withObj.push('faelle.bearbeiter');
    if (this.patient_id) {
      filter.push({filterPatientId: [this.patient_id]});
    }

    this.$$fill('anrufe', {perPage: 10, filter: filter, with: withObj, append: ['anzahl_eingehende_anrufe_woche', 'zurueckgerufen'], filter: filter});
    this.abrufen();
    this.$nuxt.$on('refresh::anruf', this.abrufenAktuelleSeite);
    this.$nuxt.$on('refresh::anrufe', this.abrufenAktuelleSeite);
    this.$nuxt.$on('updated::fall', this.abrufenAktuelleSeite);
  },
  beforeDestroy() {
    this.$nuxt.$off('refresh::anruf', this.abrufenAktuelleSeite);
    this.$nuxt.$off('refresh::anrufe', this.abrufenAktuelleSeite);
    this.$nuxt.$off('updated::fall', this.abrufenAktuelleSeite);
  }

}
