
import formularmodal from '~/mixins/formulare/formularmodal';

export default {
  mixins: [formularmodal],
  data() {
    return {
      modal: {
        id: 'fall-kommentar',
        event: 'create::fall-kommentar',
        titel: '',
        modelname: 'Fall',
        model: 'falle'
      },
    }
  },
  methods: {
    speichern() {
      // Status & Bearbeiter basierend auf Aktion setzen

      switch(this.params.aktion) {
        case 'uebergeben':
          this.model.status = 0; // Offen
          this.model.bearbeiter = []; // Bearbeiter entfernen
          break;

        case 'abschliessen':
          this.model.status = 2; // Abgeschlossen
          break;
      }

      this.$$update('faelle', this.model).then(fall => {
        this.$nuxt.$emit('updated::fall', fall);
        this.schliessen();
      });
    }
  },
  mounted() {}
}
