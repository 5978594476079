
export default {
  mixins: [],
  props: ['model', 'betreffender'],
  data() {
      return {
        betreffenderVorschlaege: [],
        betreffenderVal: this.betreffender,
        patienten: null,
      }
  },
  computed: {},
  watch: {},
  methods: {
    patientenAbrufen() {
      this.$$find('patienten', this.model.kontakt.id, '/api/patientenkontakte/viakontakt').then(response => {
        this.patienten = response;
      });
    },
    betreffenderAuswaehlen(betreffender) {
      this.betreffenderVal = betreffender;
      this.$emit('update:betreffender', betreffender);
    }
  },
  mounted() {
    if (this.model?.kontakt?.model_type?.endsWith('Patientenkontakt')) {
      this.patientenAbrufen();
    }
  },

}
