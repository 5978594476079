
export default {
  mixins: [],
  props: ['model'],
  data() {
      return {
        ausgewaehlteFaelle: [],
      }
  },
  computed: {},
  watch: {},
  methods: {
    faelleAbrufen() {
      this.$$fill('faelle', {filter: [{where: ['betreffender_id', '=', this.model.betreffender.model_id]}, {where: ['betreffender_type', '=', this.model.betreffender.model_type]}, {nichtGeschlossen: []}]});
      this.$$abrufen('faelle');
    },
    fallAuswaehlen(fall) {
      const index = this.ausgewaehlteFaelle.indexOf(fall);
      if (index === -1) {
        this.ausgewaehlteFaelle.push(fall);
      } else {
        this.ausgewaehlteFaelle.splice(index, 1);
      }
    },
    anrufStarten() {
      this.model.faelle = this.ausgewaehlteFaelle;
      this.$$store('anrufe', this.model).then(response => {
        this.$emit('done');
        this.$success('Anruf gestartet');
      });
    },
  },
  mounted() {
    this.model.neuerFall = {
      titel: '',
      neuer_kommentar: '',
    };
    this.faelleAbrufen();
  },

}
