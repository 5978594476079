
export default {
  props: {'nummer': null, 'kontakt': null, betreffender: null, startcall: {default: true}, displayBezeichnung: {default: true}},
  methods: {
    anrufStarten() {
      if (!this.startcall) return this.$emit('nummerklick', this.nummer.nummer);
      this.$emit('anruf-starten');
      this.$nuxt.$emit('display-anruf-starten', {nummer: this.nummer, kontakt: this.kontakt, betreffender: this.betreffender});
    }
  }
}
